import * as client_hooks from '../../../src/hooks/index.client.ts';
import * as universal_hooks from '../../../src/hooks/index.universal.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148')
];

export const server_loads = [0];

export const dictionary = {
		"/(nav)": [23,[2],[3]],
		"/(nav)/@[slug]": [24,[2,4],[3]],
		"/(nav)/@[slug]/about": [25,[2,4],[3]],
		"/(nav)/@[slug]/events": [26,[2,4,5],[3]],
		"/(nav)/@[slug]/guides": [27,[2,4,6],[3]],
		"/(nav)/@[slug]/members": [28,[2,4],[3]],
		"/(nav)/@[slug]/overview": [29,[2,4],[3]],
		"/(nav)/@[slug]/posts": [30,[2,4],[3]],
		"/(nav)/@[slug]/sessions": [31,[2,4,7],[3]],
		"/@[slug]/sessions/[slug]": [132],
		"/(nav)/@[slug]/settings/details": [32,[2,4,8],[3]],
		"/(nav)/@[slug]/settings/discord-roles": [33,[2,4,8],[3]],
		"/(nav)/@[slug]/settings/tiers": [34,[2,4,8],[3]],
		"/(nav)/@[slug]/settings/topics": [35,[2,4,8],[3]],
		"/(nav)/@[slug]/tiers": [36,[2,4],[3]],
		"/@[slug]/tip": [133],
		"/(nav)/@[slug]/welcome": [37,[2,4],[3]],
		"/(nav)/account": [53,[2,13],[3]],
		"/(nav)/account/chat/[id]": [54,[2,13],[3]],
		"/(nav)/account/event/[id]": [55,[2,13],[3]],
		"/(nav)/account/event/[id]/chat": [56,[2,13],[3]],
		"/(nav)/account/event/[id]/live": [57],
		"/(nav)/account/following": [58,[2,13],[3]],
		"/(nav)/account/my-stuff": [59,[2,13],[3]],
		"/(nav)/account/onboarding": [60,[2,13],[3]],
		"/(nav)/account/purchases": [61,[2,13],[3]],
		"/(nav)/account/purchases/communities": [62,[2,13],[3]],
		"/(nav)/account/purchases/events": [63,[2,13],[3]],
		"/(nav)/account/purchases/guides": [64,[2,13],[3]],
		"/(nav)/account/purchases/sessions": [65,[2,13],[3]],
		"/(nav)/account/sessions/create": [68,[2,13],[3]],
		"/(nav)/account/session/[coachingId]": [66,[2,13],[3]],
		"/(nav)/account/session/[coachingId]/[coachingSessionId]": [67,[2,13],[3]],
		"/(nav)/account/settings/about": [69,[2,13,14],[3]],
		"/(nav)/account/settings/achievements": [70,[2,13,14],[3]],
		"/(nav)/account/settings/api-keys": [71,[2,13,14],[3]],
		"/(nav)/account/settings/communities": [72,[2,13,14],[3]],
		"/(nav)/account/settings/connected-accounts": [73,[2,13,14],[3]],
		"/(nav)/account/settings/faq": [74,[2,13,14],[3]],
		"/(nav)/account/settings/gift-cards": [75,[2,13,14],[3]],
		"/(nav)/account/settings/notifications": [76,[2,13,14],[3]],
		"/(nav)/account/settings/oauth": [77,[2,13,14],[3]],
		"/(nav)/account/settings/payments": [78,[2,13,14],[3]],
		"/(nav)/account/settings/pro": [79,[2,13,14],[3]],
		"/(nav)/account/settings/security": [80,[2,13,14],[3]],
		"/(nav)/account/settings/short-links": [81,[2,13,14],[3]],
		"/(nav)/account/settings/subscriptions": [82,[2,13,14],[3]],
		"/(nav)/account/studio": [83,[2,13,15],[3]],
		"/(nav)/account/studio/availability": [84,[2,13,15],[3]],
		"/(nav)/account/studio/communities": [85,[2,13,15],[3]],
		"/(nav)/account/studio/communities/[id]": [86,[2,13,15],[3]],
		"/(nav)/account/studio/contests": [87,[2,13,15],[3]],
		"/(nav)/account/studio/customers": [88,[2,13,15],[3]],
		"/(nav)/account/studio/customers/[id]": [89,[2],[3]],
		"/(nav)/account/studio/discounts": [90,[2,13,15],[3]],
		"/(nav)/account/studio/events": [91,[2,13,15],[3]],
		"/(nav)/account/studio/events/[id]": [92,[2,13,15],[3]],
		"/(nav)/account/studio/gift-cards": [93,[2,13,15],[3]],
		"/(nav)/account/studio/guides": [94,[2,13,15],[3]],
		"/(nav)/account/studio/overview": [95,[2,13,15],[3]],
		"/(nav)/account/studio/referrals": [96,[2,13,15],[3]],
		"/(nav)/account/studio/reviews": [97,[2,13,15],[3]],
		"/(nav)/account/studio/sessions": [98,[2,13,15],[3]],
		"/(nav)/account/studio/sessions/completed": [99,[2,13,15],[3]],
		"/(nav)/account/studio/sessions/settings": [100,[2,13,15],[3]],
		"/(nav)/account/studio/videos": [101,[2,13,15],[3]],
		"/(nav)/account/wallet": [102,[2,13],[3]],
		"/auth/account/confirm-email": [134,[19]],
		"/auth/account/create": [135,[19]],
		"/auth/account/forgot-password": [136,[19]],
		"/auth/account/login": [137,[19]],
		"/auth/account/reset-password": [138,[19]],
		"/auth/account/verify-email": [139,[19]],
		"/auth/authorize": [~140],
		"/auth/external/[...provider]": [141,[20]],
		"/checkout": [142,[21]],
		"/checkout/reschedule": [143,[21]],
		"/(nav)/communities": [103,[2],[3]],
		"/(nav)/communities/create": [112,[2],[3]],
		"/(nav)/communities/mine": [113,[2],[3]],
		"/(nav)/communities/[filter=groupsFilter]": [104,[2],[3]],
		"/(nav)/communities/[slug]": [105,[2],[3]],
		"/(nav)/communities/[slug]/events": [106,[2],[3]],
		"/(nav)/communities/[slug]/guides": [107,[2],[3]],
		"/(nav)/communities/[slug]/members": [108,[2],[3]],
		"/(nav)/communities/[slug]/overview": [109,[2],[3]],
		"/(nav)/communities/[slug]/sessions": [110,[2],[3]],
		"/(nav)/communities/[slug]/tiers": [111,[2],[3]],
		"/(nav)/events": [114,[2,17],[3]],
		"/(nav)/events/mine": [117,[2,17],[3]],
		"/(nav)/events/replays": [118,[2,17],[3]],
		"/(nav)/events/replays/mine": [119,[2,17],[3]],
		"/(nav)/events/[filter=eventsFilter]": [115,[2,17],[3]],
		"/(nav)/events/[slug]": [116,[2],[3]],
		"/(nav)/guides": [120,[2],[3]],
		"/(nav)/guides/create": [122,[2],[3]],
		"/guides/create/[guideSlug]": [144,[22]],
		"/guides/create/[guideSlug]/[chapterSlug]": [145,[22]],
		"/(nav)/guides/mine": [123,[2],[3]],
		"/guides/preview/[guideSlug]/[chapterSlug]": [146,[22]],
		"/(nav)/guides/view/[guideSlug]": [124,[2],[3]],
		"/(nav)/guides/view/[guideSlug]/[chapterSlug]": [125,[2],[3]],
		"/(nav)/guides/[type=guidesFilter]": [121,[2],[3]],
		"/(nav)/posts/[id]": [126,[2],[3]],
		"/pro": [147],
		"/(nav)/rules": [127,[2],[3]],
		"/(nav)/sessions": [128,[2,18],[3]],
		"/(nav)/sessions/mine": [130,[2,18],[3]],
		"/(nav)/sessions/[filter]": [129,[2,18],[3]],
		"/tags/[...anything]": [148],
		"/(nav)/the-first-annual-metafy-monthly-contest": [131,[2],[3]],
		"/(nav)/[game]": [38,[2,9],[3]],
		"/(nav)/[game]/communities": [39,[2,9],[3]],
		"/(nav)/[game]/communities/mine": [41,[2,9],[3]],
		"/(nav)/[game]/communities/[filter=groupsFilter]": [40,[2,9],[3]],
		"/(nav)/[game]/events": [42,[2,9,10],[3]],
		"/(nav)/[game]/events/mine": [44,[2,9,10],[3]],
		"/(nav)/[game]/events/replays": [45,[2,9,10],[3]],
		"/(nav)/[game]/events/replays/mine": [46,[2,9,10],[3]],
		"/(nav)/[game]/events/[filter=eventsFilter]": [43,[2,9,10],[3]],
		"/(nav)/[game]/guides": [47,[2,9,11],[3]],
		"/(nav)/[game]/guides/mine": [49,[2,9,11],[3]],
		"/(nav)/[game]/guides/[type]": [48,[2,9,11],[3]],
		"/(nav)/[game]/sessions": [50,[2,9,12],[3]],
		"/(nav)/[game]/sessions/mine": [52,[2,9,12],[3]],
		"/(nav)/[game]/sessions/[filter]": [51,[2,9,12],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';